body {
  margin: 0;
  text-align: center;

  /* background-color: #60676B; */
  /* background-color: #79a375; */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='94' height='94' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23678b63' fill-opacity='0.37'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.37'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E"); */
}

.landingImage {
  background-image: url(./images/MoneyEnvelope.jpeg);
}
.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 110px 0 0 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
  position: fixed;
  z-index: 105;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: #1976d2;
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: #f44336;
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: #4caf50;
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 2rem;
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
  border-bottom: solid 1px #3f51b5;
  background: #fff;
  height: 10%;
}

.navbar ul {
  display: flex;
  margin: 0 2rem 0 0;
}

.navbar a {
  /* color: #000; */
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.navbaricon {
  float: left;
}
